/**/
/* Helper classes*/
/* --------------------------------------------------*/

.btn-theme-default {
  @include button-variant-outline( $white, $theme-color-default, $theme-color-default,  $white, $theme-color, $theme-color);
  padding-left: 40px;
  padding-right: 40px;
}
.btn-inverse {
    @include button-variant($theme-color, transparent, transparent);
}
.btn-lighten {
  @include button-variant(#fff, transparent, #fff);
}
.btn-outline{
    @include button-outline(second, $link-color, #FFFFFF );
    @include button-outline(lighten, #fff, $link-hover-color);
    @include button-outline(default, $link-color, #FFFFFF );
    @include button-outline(primary, $brand-primary, #FFFFFF );
    @include button-outline(success, $brand-success, #FFFFFF );
    @include button-outline(info, $brand-info, #FFFFFF );
    @include button-outline(danger, $brand-danger, #FFFFFF );
    @include button-outline(warning, $brand-warning, #FFFFFF );
}

/*text*/
.text-white {
    color: $white;
}
 
/* CSS Document */
.swiper-viewport {
    background: #fff;
    z-index: 1;
    width: 100%;
    position: relative;
    overflow: visible;
    margin: 0px;
    box-shadow: none;
    border-radius: 0;
    border: none;
}
/* OpenCart Code */
.swiper-container {
    direction: ltr !important;
}
.swiper-pager {
    width: 100%;
    position: absolute;
    top: 50%;
    line-height: 45px;
}
.swiper-button-prev, .swiper-button-next {
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    transition: all .3s ease;
    z-index: 2;
    background-image: none;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
}
.swiper-viewport {
    .swiper-button-prev {
        opacity: 0.7;
        left: 10px;
    }
    .swiper-button-next {
        opacity: 0.7;
        right: 10px;   
    }
}

.space-15 {
    margin-bottom: 15px;
}
.space-20 {
    margin-bottom: 20px;
}
.space-25 {
    margin-bottom: 25px;
}
.space-30 {
    margin-bottom: 30px;
}
.space-35 {
    margin-bottom: 35px;
}
.space-40 {
    margin-bottom: 40px;
}
.space-45 {
    margin-bottom: 45px;
}
.space-50 {
    margin-bottom: 50px;
}
.space-55 {
    margin-bottom: 55px;
}
.space-60 {
    margin-bottom: 60px;
}
.space-65 {
    margin-bottom: 65px;
}
.space-70 {
    margin-bottom: 70px;
}

.space-top-10 {
    margin-top: 10px;
}
.space-top-15 {
    margin-top: 15px;
}
.space-top-20 {
    margin-top: 20px;
}
.space-top-25 {
    margin-top: 25px;
}
.space-top-30 {
    margin-top: 30px;
}
.space-top-35 {
    margin-top: 35px;
}
.space-top-40 {
    margin-top: 40px;
}
.space-top-45 {
    margin-top: 45px;
}
.space-top-50 {
    margin-top: 50px;
}
.space-top-55 {
    margin-top: 55px;
}
.space-top-60 {
    margin-top: 60px;
}
.space-top-65 {
    margin-top: 65px;
}
.space-top-70 {
    margin-top: 70px;
}
/* Desktop */
@media (min-width: 768px){
    .swiper-viewport {
        .swiper-button-prev { 
            left: -20px;
        }
        .swiper-button-next {
            right: -20px;
        }
        .swiper-button-prev, .swiper-button-next {
            opacity: 0;
        }
        &:hover {
            .swiper-button-prev {
                left: 10px;
            }
            .swiper-button-next {
                right: 10px;
            }
            .swiper-button-prev, .swiper-button-next {
                opacity: 1;
            }
        }
    }
}
.swiper-button-prev {
    &:before {
        content: "\f100"; 
    }
}
.swiper-button-next {
    &:before {
        content: "\f101"; 
    }
}
.swiper-button-prev, .swiper-button-next {
    &:before {
        position: absolute;;
        left: 40%;
        font-family: $icon-font-name;
        color: rgba(0,0,0,0.8);
        font-size: $font-size-large;
    }
}
.swiper-pagination {
    display: none;
    bottom: -40px;
    left: 0;
    text-align: center;
    width: 100%;
}
.swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    margin: 0 5px;
    opacity: 1;
}
.swiper-pagination-bullet {
    &:hover {
        background: rgba(0, 0, 0, 0.7);
    }
}
.swiper-pagination-bullet-active {
    background: rgba(0, 0, 0, 0.9);
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
    cursor: pointer;
    pointer-events: auto;
}


.effect-v1 {
    overflow: hidden;
    img {
        outline: transparent solid 15px;
        outline-offset: 0px;
        @include opacity(1);
        @include transition (all 0.35s ease 0s);
    }
    &:hover {
        img {
            outline-color: rgba(0,0,0,0.2);
            outline-offset: -30px;
        }
    }
} 

/* effect-v2*/
.effect-v2{
    background-color: #000;
    overflow: hidden;
    position: relative;
    &:before {
        border: 1px solid #ffffff;
        bottom: 20px;
        content: "";
        left: 20px;
        position: absolute;
        right: 20px;
        top: 20px;
        @include translate3d (-20px, 0px, 0px);
        @include opacity(0);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
        z-index: 1;
    }
    img {
        @include scale (1, 1);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }
    .action{
        @include translate3d(-10px, 0px, 0px);
        @include opacity(0);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }
    /* style for hover */
    &:hover{
        &:before {
            @include opacity(1);
            @include translate3d(0px, 0px, 0px);
        }
        img {
            @include opacity(0.7);
            @include scale (1.2, 1);
        }
        .action{
            @include translate3d(0px, 0px, 0px);
            @include opacity(1);
        }
    }
}
/* effect-v3*/
.effect-v3{
    overflow: hidden;
    position: relative;
    &:before,&:after {
        bottom: 20px;
        content: "";
        left: 20px;
        opacity: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
        z-index: 1;
    }
    &:before {
        border-bottom: 1px solid #ffffff;
        border-top: 1px solid #ffffff;
        @include scale(0, 1);
    }
    &:after {
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        @include scale(1, 0);
    }
    img {
        @include opacity(1);
        @include transition (opacity 0.35s ease 0s);
    }
    .banner-title{
        @include translate3d(0px, -20px, 0px);
        @include transition (transform 0.35s ease 0s);
    }
    .action{
        @include opacity(0);
        @include translate3d(0px, 40px, 0px);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }
    &:hover{
        &:before,
        &:after {
            @include opacity(1);
            @include scale(1);
        }
        img {
            @include opacity(0.5);
        }
        .banner-title,.action{
            @include opacity(1);
            @include translate3d(0px, 0px, 0px);
        }
    }
}
/* effect-v4*/
.effect-v4{
    background-color: #000;
    overflow: hidden;
    position: relative;
    &:before {
        border: 2px solid #ffffff;
        bottom: 20px;
        @include box-shadow(0 0 0 30px rgba(255, 255, 255, 0.2));
        content: "";
        left: 20px;
        opacity: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        @include scale(1.4);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
        z-index: 1;
    }
    img {
        @include scale (1, 1);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }
    .banner-title{
                @include scale(1);
                @include transition (transform 0.35s ease 0s);
            }
            .action{
                @include opacity(0);
                @include scale(1.2);
                @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
            }
    &:hover{
        &:before {
            @include opacity(1);
            @include scale(1);
        }
        img {
            @include opacity(0.5);
        }
        .banner-title{
            @include scale(0.95);
        }
        .action{
            @include opacity(1);
            @include scale(1);
        }
    }
}
/* effect-v5*/
.effect-v5{
    background-color: #000;
    overflow: hidden;
    position: relative;
    img {
        @include scale (1, 1);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }
    .action {
        transform: translate3d(-150%, 0px, 0px);
        @include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);

        &:first-child {
            @include transition-delay(0.15s);
        }
        &:nth-of-type(2) {
            @include transition-delay (0.1s);
        }
        &:nth-of-type(3) {
            @include transition-delay (0.05s);
        }
    }
    &:hover{
        img {
            @include opacity(0.5);
            transform: scale3d(1.1, 1.1, 1);
        }
        .action {
            @include opacity(1);
            transform: scale3d(1.1, 1.1, 1);
            transform: translate3d(0px, 0px, 0px);

            &:first-child {
                @include transition-delay (0s);
            }
            &:nth-of-type(2) {
                @include transition-delay (0.05s);
            }
            &:nth-of-type(3) {
                @include transition-delay (0.1s);
            }
        }

    }
}
/* effect-v6*/
.effect-v6{
    background-color: #000;
    overflow: hidden;
    position: relative;
    img {
        @include scale (1, 1);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }
    .action {
        transform: translate3d(0px, -100px, 0px);
        transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
        opacity: 0;
    }
    &:hover{
        img {
            @include opacity(0.5);
        }
        .action {
            opacity: 1;
            transform: translate3d(0px, 0px, 0px);
        }
    }
}
/* effect-v7*/
.effect-v7{
    overflow: hidden;
    position: relative;
    &:before {
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include transition(all 0.4s cubic-bezier(0.94, 0.85, 0.1, 0.62) 0s);
        z-index: 1;
        border: 200px double rgba(0, 0, 0, 0);
        @include opacity(1);
        visibility: visible;
    }
    &:hover{
        &:before {
            @include opacity(0);
            border: 0 double rgba(255, 255, 255, 0.7);
            visibility: hidden;
        }
    }
}
/* effect-v8*/
.effect-v8{
    overflow: hidden;
    position: relative;
    &:before {
        border-bottom: 50px solid rgba(255, 255, 255, 0.4);
        border-top: 50px solid rgba(255, 255, 255, 0.4);
        content: "";
        height: 100%;
        left: 0;
        @include opacity(0);
        position: absolute;
        top: 0;
        transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        @include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);
        transform-origin: 50% 50% 0;
        width: 100%;
    }
    &:hover{
        &:before {
            @include opacity(1);
            transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
            -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
            -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
            -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
            -ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
        }
    }
}
/* effect-v9*/
.effect-v9{
    overflow: hidden;
    position: relative;
    &:before {
        border: 50px solid rgba(0, 0, 0, 0);
        @include border-top-radius (50px);
        @include border-bottom-radius (50px);
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        top: 0;
        content: "";
        @include opacity (1);
        @include size(100px, 100px);
        @include scale(7);
        @include transition (all 0.3s ease-in-out 0s);
        visibility: visible;
        z-index: 1;
    }
    &:hover{
        &:before {
            border: 0 solid rgba(0, 0, 0, 0.7);
            @include opacity (0);
            visibility: hidden;
        }
    }
}
/* effect-v10*/
.effect-v10{
    overflow: hidden;
    position: relative;
    &:before {
        background-color: rgba(255, 255, 255, 0.2);
        display: block;
        @include size(100%, 100%);
        left: -100%;
        @include opacity (0);
        position: absolute;
        top: 0;
        @include transition (all 0.3s ease 0s);
        content: "";
        z-index: 1;
    }
    &:hover{
        &:before {
          left: 0;
          @include opacity (1);
        }
    }
}
/* effect-v11*/
.effect-default{
    background-color: #000;
    overflow: hidden;
    position: relative;
    .banner-title{
        @include translate3d(0px, 20px, 0px);
        @include transition (transform 0.35s ease 0s);
    }
    img {
        @include scale (1.15);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
        @include opacity (0.7);
    }
    .action{
        @include scale (1.1);
        @include opacity(0);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
        border: 1px solid #fff;
        margin: 1em 0 0;
        padding: 1em;
        color: $white;
    }
    &:hover{
        &:before {
            @include opacity(1);
            @include scale (1);
        }
        img {
            @include opacity(0.5);
            @include scale (1);
        }
        .action{
            @include scale (1);
            @include opacity(1);
        }
        .banner-title{
            @include opacity(1);
            @include translate3d(0px, 0px, 0px);
        }
    }
}