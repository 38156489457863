/*
 * Global variables
 */
$image-theme-path                : '../image/' !default;
$image-theme-skin                : '../image/' !default;
$theme-font-path                 : '../fonts/' !default;
// standard colors
$white		    : #FFF !default;
$black			: #000 !default;
$nocolor		: transparent !default;

// color for default theme
$theme-color    : #222222 !default; /* main color which will be used for all main block styles... */
$border-color	: #e1e1e1 !default;

$theme-color-default             : $link-color !default;
$theme-color-second  			 : #333333 !default;
$theme-color-third  			 : #666666 !default;
$theme-color-four  			     : #999999 !default;


// Theme Margin, Padding
// -------------------------
$theme-margin    : 30px !default;
$theme-padding   : 30px !default;
// fonts
$font-family-default: $font-family-base !default;
$font-family-third: $font-family-base !default;
$font-family-second :  $font-family-base !default;
$theme-padding : 30px !default;
/**
 * Import component variables
 */


@import "vars/elements";
@import "vars/product";
@import "vars/layout";
@import "vars/modules";
