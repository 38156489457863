/** Product deals**/

.flashsale-content {
	.product-timing-deal {
	    position: absolute;
	    bottom: 20px;
	    left: 0;
	    right: 0;
	}
	.format-date-days {
		ul {
			margin: 0px;
			li {
				background: #355c7d;
				margin: 0 3px;
				line-height: 0;
				color: #ffffff;
				font-size: 10px;
				font-weight: 400;
				border-radius: 3px;
				width: 40px;
				height: 40px;
				box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.09);
			}
		}
		.time-group {
			font-size: 16px;
		    font-weight: 700;
		    line-height: 30px;
		}
	}
}