.product-block {
    background-color: $white;
    margin: 0 1px;
    border: 1px solid transparent;
    .product-meta{
        position: relative;
    }
    &.product-v2{
        position: relative;
        @include rtl-padding-left(15px);
        .image{
            margin: 30px 0;
        }
    }
    .btn-style {
        display: inline-block;
        position: relative;
        color: $cart-icon-color;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-base;
        margin: 2px 0;
        padding: 12px;
        &:last-child{
            border:none;
        }
        i{
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
        }
    }
    .bottom {
        position: relative;
    }
}
@media (max-width: $screen-md){

}
@media (min-width: $screen-md){
    .product-thumb {
        .group-action {
            position: relative;
            .button-group {    
                display: block;
                @include opacity(0);
                @include transition (all .35s ease 0s);
                z-index: 999;
                @include scale(0);
            }
            .price {
                position: absolute;
                @include scale(1);
                z-index: 10;
                width: 100%;
            }
        }
        &:hover {
            .button-group {
                @include opacity(1);
                @include scale(1);
            }
            .price {
                @include opacity(0);               
                @include scale(0);
            }
        }
    }
}

