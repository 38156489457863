// Support for RTL (Right to Left) & non-latin fonts

$rtl-left : left;
$rtl-right : right;
$rtl-center: center;

// BASIC CONVERTER (ignore these)

@mixin rtl-base-simple ($property, $direction) {
  #{$property}:$direction;
  .rtl & {
    @if $direction == $rtl-right {
      #{$property}:$rtl-left;
    }
    @else {
      #{$property}:$rtl-right;
    }
  }
}
@mixin rtl-base-inherit ($property, $direction, $value, $inherit : inherit) {
  #{$property}-#{$direction}: $value;
  .rtl & {
    @if $direction == $rtl-right {
      #{$property}-#{$rtl-left}: $value;
    }
    @else {
      #{$property}-#{$rtl-right}: $value;
    }
    #{$property}-#{$direction}: $inherit;
  }
}

@mixin rtl-base-toprightbottomleft ($property, $t, $r, $b, $l) {
  #{$property}: $t $r $b $l;
  .rtl & {
    #{$property}: $t $l $b $r;
  }
}

// BODY STYLES

@mixin rtl-direction ($forBody : true) {
  direction: ltr;
  @if $forBody {
    &.rtl {
      direction: rtl;
    }
  }
  @else {
    .rtl & {
      direction: rtl;
    }
  }
}

@mixin rtl-font-family ($ltr, $rtl, $forBody : false) {
  font-family: $ltr;
  @if $forBody {
    &.rtl, &.non-latin {
      font-family:$rtl;
    }
  }
  @else {
    .rtl &, .non-latin & {
      font-family:$rtl;
    }
  }
}


// MARGIN

@mixin rtl-margin ($t, $r, $b, $l) {
  @include rtl-base-toprightbottomleft(margin,$t, $r, $b, $l);
}
@mixin rtl-margin-left ($value) {
  @include rtl-base-inherit(margin,$rtl-left,$value);
}
@mixin rtl-margin-right ($value) {
  @include rtl-base-inherit(margin,$rtl-right,$value);
}

// PADDING

@mixin rtl-padding ($t, $r, $b, $l) {
  @include rtl-base-toprightbottomleft(padding,$t, $r, $b, $l);
}
@mixin rtl-padding-left ($value) {
  @include rtl-base-inherit(padding,$rtl-left,$value);
}
@mixin rtl-padding-right ($value) {
  @include rtl-base-inherit(padding,$rtl-right,$value);
}

// BORDER

@mixin rtl-border-left ($value) {
  @include rtl-base-inherit(border,$rtl-left,$value);
}
@mixin rtl-border-right ($value) {
  @include rtl-base-inherit(border,$rtl-right,$value);
}

// POSITION

@mixin rtl-left ($value) {
  #{$rtl-left}: $value;
  .rtl & {
    #{$rtl-right}: $value;
    #{$rtl-left}: auto;
  }
}
@mixin rtl-right ($value) {
  #{$rtl-right}: $value;
  .rtl & {
    #{$rtl-left}: $value;
    #{$rtl-right}: auto;
  }
}


// CLEAR

@mixin rtl-clear-left () {
  @include rtl-base-simple(clear, $rtl-left);
}
@mixin rtl-clear-right () {
  @include rtl-base-simple(clear, $rtl-right);
}

// TEXT-ALIGN

@mixin rtl-text-align-left () {
  @include rtl-base-simple(text-align, $rtl-left);
}
@mixin rtl-text-align-right () {
  @include rtl-base-simple(text-align, $rtl-right);
}
@mixin rtl-text-align-center () {
  @include rtl-base-simple(text-align, $rtl-center);
}

// FLOAT

@mixin rtl-float-left () {
  @include rtl-base-simple(float, $rtl-left);
}
@mixin rtl-float-right () {
  @include rtl-base-simple(float, $rtl-right);
}

// BACKGROUND-POSITION

@mixin rtl-background-position-left ($vertical) {
  background-position:$rtl-left $vertical;
  .rtl & {
    background-position:$rtl-right $vertical;
  }
}
@mixin rtl-background-position-right ($vertical) {
  background-position:$rtl-right $vertical;
  .rtl & {
    background-position:$rtl-left $vertical;
  }
}

@mixin rtl-background-position-percent ($vertical, $horPercent) {
  background-position:$horPercent $vertical;
  .rtl & {
    background-position:100% - $horPercent $vertical;
  }
}

// TEXT-SHADOW & BOX-SHADOW

@mixin rtl-text-shadow ($x, $rest) {
  text-shadow: $x $rest;
  .rtl & {
    text-shadow: -1 * $x $rest;
  }
}
@mixin rtl-box-shadow ($x, $rest) {
  -moz-box-shadow: $x $rest;
  -webkit-box-shadow: $x $rest;
  box-shadow: $x $rest;
  .rtl & {
    -moz-box-shadow: -1 * $x $rest;
    -webkit-box-shadow: -1 * $x $rest;
    box-shadow: -1 * $x $rest;
  }
}

// BORDER-RADIUS

@mixin rtl-border-radius-topright ($tl, $tr, $br, $bl) {
  -moz-border-radius: $tl, $tr, $br, $bl;
  -webkit-border-radius: $tl, $tr, $br, $bl;
  border-top-radius: $tl, $tr, $br, $bl;
  .rtl & {
    -moz-border-radius: $tr, $tl, $bl, $br;
    -webkit-border-radius: $tr, $tl, $bl, $br;
    border-top-radius: $tr, $tl, $bl, $br;
  }
}

@mixin rtl-border-radius-topright ($value) {
  -moz-border-radius-top#{$rtl-right}: $value;
  -webkit-border-top-#{$rtl-right}-radius: $value;
  border-top-#{$rtl-right}-radius: $value;
  .rtl & {
    -moz-border-radius-top#{$rtl-left}: $value;
    -webkit-border-top-#{$rtl-left}-radius: $value;
    border-top-#{$rtl-left}-radius: $value;
    -moz-border-radius-top#{$rtl-right}: inherit;
    -webkit-border-top-#{$rtl-right}-radius: inherit;
    border-top-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomright ($value) {
  -moz-border-radius-bottom#{$rtl-right}: $value;
  -webkit-border-bottom-#{$rtl-right}-radius: $value;
  border-bottom-#{$rtl-right}-radius: $value;
  .rtl & {
    -moz-border-radius-bottom#{$rtl-left}: $value;
    -webkit-border-bottom-#{$rtl-left}-radius: $value;
    border-bottom-#{$rtl-left}-radius: $value;
    -moz-border-radius-bottom#{$rtl-right}: inherit;
    -webkit-border-bottom-#{$rtl-right}-radius: inherit;
    border-bottom-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-topleft ($value) {
  -moz-border-radius-top#{$rtl-left}: $value;
  -webkit-border-top-#{$rtl-left}-radius: $value;
  border-top-#{$rtl-left}-radius: $value;
  .rtl & {
    -moz-border-radius-top#{$rtl-right}: $value;
    -webkit-border-top-#{$rtl-right}-radius: $value;
    border-top-#{$rtl-right}-radius: $value;
    -moz-border-radius-top#{$rtl-left}: inherit;
    -webkit-border-top-#{$rtl-left}-radius: inherit;
    border-top-#{$rtl-left}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomleft ($value) {
  -moz-border-radius-bottom#{$rtl-left}: $value;
  -webkit-border-bottom-#{$rtl-left}-radius: $value;
  border-bottom-#{$rtl-left}-radius: $value;
  .rtl & {
    -moz-border-radius-bottom#{$rtl-right}: $value;
    -webkit-border-bottom-#{$rtl-right}-radius: $value;
    border-bottom-#{$rtl-right}-radius: $value;
    -moz-border-radius-bottom#{$rtl-left}: inherit;
    -webkit-border-bottom-#{$rtl-left}-radius: inherit;
    border-bottom-#{$rtl-left}-radius: inherit;
  }
}


@mixin rtl-translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9 only
       -o-transform: translate($x, $y);
          transform: translate($x, $y);

        .rtl & {
             -webkit-transform: translate(-$x, $y);
            -ms-transform: translate(-$x, $y); // IE9 only
             -o-transform: translate(-$x, $y);
                transform: translate(-$x, $y);
        }
}

@mixin rtl-translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
      -ms-transform: translate3d($x, $y, $z); // IE9 only
       -o-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);

        .rtl & {
             -webkit-transform: translate3d(-$x, $y, $z);
            -ms-transform: translate3d(-$x, $y, $z); // IE9 only
             -o-transform: translate3d(-$x, $y, $z);
                transform: translate3d(-$x, $y, $z);
        }
}
//rotate
@mixin rtl-rotate ($value) {
  @include rotate($value);
  .rtl & {
    @include rotate(-$value);
  }
}



// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    float: right;
  }
}


@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index == 0) {
    .col-#{$class}-push-0 {
      right: auto;
    }
  }
  @if ($type == pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type == pull) and ($index == 0) {
    .col-#{$class}-pull-0 {
      left: auto;
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-right: percentage(($index / $grid-columns));
    }
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}


// Create grid for specific class
@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset);
}
